import React from "react";
import styled, { css } from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const SidebarContainer = styled.div`
  width: 250px;
  background-color: #9C41FF;
  color: #F4F4F4;
  padding: 0 10px;
  height: 100vh;
  position: fixed;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 40px;
  margin-top: 40px;
  padding: 10px;
`;

const BusinessName = styled.h3`
  font-size: 22px;
  margin: 10px 0 40px;
  text-align: center;
`;

const Role = styled.span`
  font-size: 14px;  // Smaller font size for "매니저"
`;

const MenuList = styled.ul`
  list-style: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: 15px;
`;

const MenuItem = styled.li`
  font-size: 16px;
  letter-spacing: +0.05em;
  cursor: pointer;
  color: #B0B0B0; /* Default color */

  ${props => props.active && css`
    color: #F4F4F4; /* Active item color */
    font-weight: bold;
  `}

  &:hover {
    color: #F4F4F4; /* Light grey color on hover */
  }
`;

const Footer = styled.footer`
  text-align: center;
  font-size: 12px;
  margin-top: auto;
`;

const Sidebar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { currentUser, logout } = useAuth();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const menuItems = [
    { name: "신고 목록", path: "/report" },
    { name: "문의 목록", path: "/inquiry" },
    { name: "프로필 사진 인증", path: "/verify-profile-image" },
    { name: "비밀번호 변경", path: "/change-password" }
  ];

  return (
    <SidebarContainer>
      <div>
        <Title>미팅GO</Title>
        <BusinessName>{currentUser.name} <Role>매니저</Role></BusinessName>
        <MenuList>
          {menuItems.map(item => (
            <MenuItem
              key={item.name}
              onClick={() => navigate(item.path)}
              active={pathname === item.path}
            >
              {item.name}
            </MenuItem>
          ))}
          <MenuItem onClick={handleLogout}>로그아웃</MenuItem>
        </MenuList>
      </div>
      <Footer>
        <p>Copyright 2024 미팅GO</p>
      </Footer>
    </SidebarContainer>
  );
};

export default Sidebar;
