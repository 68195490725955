import React from "react";
import styled from "styled-components";

import Sidebar from "../components/Sidebar";
import ReportList from "../components/ReportList";
import UserSearch from "../components/UserSearch";
import { useAuth } from "../contexts/AuthContext";
import reportIcon from "./assets/image.png";

const Container = styled.div`
  display: flex;
  height: 100vh;
`;

const ContentContainer = styled.div`
  flex: 1;
  background-color: #F4F4F4;
  padding: 20px;
`;

const Title = styled.h1`
  color: #000000;
  margin-top: 50px;
  font-size: 24px;
  margin-left: 250px;
  padding: 20px;
`;

const SectionContainer = styled.div`
  display: flex;
  gap: 20px;
`;


const AdminPage = () => {
  const { currentUser } = useAuth();
  return (
    <Container>
      <Sidebar />
      <ContentContainer>
        <Title>
          <img src={reportIcon} alt="Report Icon" style={{ marginRight: '10px', height: '20px' }} />
           신고 목록</Title>
        <ReportList />
        <UserSearch />
      </ContentContainer>
    </Container>
  );
};

export default AdminPage;
