import React, { useContext, useState, useEffect } from "react";
import axios from "axios";

const AuthContext = React.createContext();

export const useAuth = () => useContext(AuthContext);

// Environment variables for the temp admin account
const TEMP_ADMIN_EMAIL = process.env.REACT_APP_TEMP_ADMIN_EMAIL;
const TEMP_ADMIN_PASSWORD = process.env.REACT_APP_TEMP_ADMIN_PASSWORD;


const backendUrl = "https://" + process.env.REACT_APP_API_DNS + "/api";

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const userInfo = sessionStorage.getItem('currentUser');
    if (userInfo) {
      setCurrentUser(JSON.parse(userInfo));
    }
    setLoading(false);
  }, []);


  const login = async (name, password) => {
    try {
      const loginURL = backendUrl + "/admin/adminLogin";
      const response = await axios.post(loginURL, { name, password });

      if (response.status === 200) {
        const { userId, refreshToken, accessToken } = response.data;
        const user = { name, adminId: userId, centerName: "Admin", accessToken: accessToken };

        sessionStorage.setItem("currentUser", JSON.stringify(user));
        sessionStorage.setItem("accessToken", accessToken);
        document.cookie = `refreshToken=${refreshToken};path=/;Secure;HttpOnly;SameSite=Strict`;

        setCurrentUser(user);
        return true;
      } else {
        console.error("Login failed: ", response.data.message);
        return false;
      }
    } catch (error) {
      console.error("Failed to log in", error.response ? error.response.data.message : error.message);
      return false;
    }
  };

  const logout = () => {
    localStorage.removeItem("currentUser");
    setCurrentUser(null);
  };

  const value = {
    currentUser,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
