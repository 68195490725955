import React, { useState } from "react";
import styled from "styled-components";
import dummyImg from "../pages/assets/dummy.jpg"; // 이미지 임포트
import dummyVid from "../pages/assets/ab2cd81153f2128a14b6120f384516d4_w200.webp";

// 스타일 정의
const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Divider = styled.hr`
  border: 1px solid #CCCCCC;
`;

const VerticalContainer = styled.div`
  display: flex;
  margin-left: 250px;
  flex-direction: column;  // This will stack children vertically
  width: calc(100% - 250px);
`;

const HorizontalContainer = styled.div`
  display: flex;
  justify-content: space-between;  // Keeps sections spaced evenly
  width: 100%;  // Use the full width
  margin-left: 10px;
  margin-bottom: 20px;
  background-color: #F4F4F4;
`;

const Section = styled.div`
  background-color: #FFFFFF;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  min-height: 400px;
  margin: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
  width: ${props => props.width || '45%'};
`;

const Title = styled.h2`
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  margin-top: 0;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
  th, td {
    padding: 10px;
    border-bottom: 1px solid #CCCCCC;
  }
  th {
    text-align: left;
  }
  tr:hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }
`;

const Button = styled.button`
  padding: 10px 25px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  color: white;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: ${props => props.accept ? 'linear-gradient(45deg, #56ab2f, #a8e063)' : 'linear-gradient(45deg, #ff416c, #ff4b2b)'};
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
`;

const MediaPreview = styled.div`
  width: 100%;
  padding-top: 100%; // Aspect ratio 1:1 (square)
  position: relative;
  margin-bottom: 10px;
`;

const ImagePreview = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const VideoPreview = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Label1 = styled.label`
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  flex: 0 0 100px;
`;

const DetailRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
  gap: 30px;
`;

const Value = styled.span`
  font-weight: normal;
  margin-bottom: 5px;
  font-size: 14px;
  color: #333; // Dark gray for better readability
  display: inline-block;
  flex-grow: 1;
`;

const ProfilePhotoVerification = () => {
  const [selectedRequest, setSelectedRequest] = useState(null);

  // 더미 데이터
  const requests = [
    { id: 1, date: '2024-07-09', user: '차은우', status: '미인증', size: '30MB' },
    { id: 2, date: '2024-07-08', user: '동카(동카리나)', status: '미인증', size: '12MB' },
    { id: 3, date: '2024-07-07', user: '화면의', status: '승인됨', size: '3MB' },
    { id: 4, date: '2024-07-06', user: '웹사이트', status: '승인됨', size: '827KB' },
    { id: 5, date: '2024-07-05', user: '국민대 국민', status: '거부됨', size: '15MB' },
    // 추가 요청들...
  ];

  // 요청 항목 선택 핸들러
  const handleRequestClick = (request) => {
    setSelectedRequest(request);
  };

  return (
    <VerticalContainer>
      <HorizontalContainer>
        <Section width="35%" id="tableSection">
          <Title>인증 요청 목록</Title>
          <Divider />
          <Table>
            <thead>
              <tr>
                <th>일자</th>
                <th>유저</th>
                <th>인증 여부</th>
                <th>파일 크기</th>
              </tr>
            </thead>
            <tbody>
              {requests.map(request => (
                <tr key={request.id} onClick={() => handleRequestClick(request)}>
                  <td>{request.date}</td>
                  <td>{request.user}</td>
                  <td>{request.status}</td>
                  <td>{request.size}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {/* 페이지네이션 등 추가 필요 시 여기에 작성 */}
        </Section>

        <Section width="60%" id="detailSection">
          <Title>프로필 사진 인증하기</Title>
          <Divider />
          {selectedRequest ? (
            <>
              <DetailRow>
                <Label1>요청 일시 </Label1>
                <Value>{new Date().toLocaleString('ko-KR', { timeZone: 'Asia/Seoul' })}</Value>
              </DetailRow>
              <DetailRow>
                <Label1>유저 </Label1>
                <Value>{selectedRequest.user}</Value>
              </DetailRow>
              <DetailRow>
                <div style={{ flex: 1, marginTop: '15px'}}>
                  <Label1>인증 요청 사진 </Label1>
                  <MediaPreview>
                    <ImagePreview src={dummyImg} alt="인증 요청 사진" /> {/* 임포트한 이미지 사용 */}
                  </MediaPreview>
                </div>
                <div style={{ flex: 1, marginTop: '15px'}}>
                  <Label1>실시간 촬영 영상 </Label1>
                  <MediaPreview>
                    {/* <VideoPreview controls>
                      <source src={dummyVid} type="video/mp4" />
                    </VideoPreview> */}
                    <ImagePreview src={dummyVid} alt="인증 요청 사진" />
                  </MediaPreview>
                </div>
              </DetailRow>
              <ButtonGroup>
                <Button accept>승인</Button>
                <Button>거절</Button>
              </ButtonGroup>
            </>
          ) : (
            <p>인증 요청 항목을 선택해 주세요.</p>
          )}
        </Section>
      </HorizontalContainer>
    </VerticalContainer>
  );
};

export default ProfilePhotoVerification;
