import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import ReportPage from './pages/ReportPage';
import InquiryPage from "./pages/InquiryPage";
import LoginPage from "./pages/LoginPage";
import VerifyProfileImagePage from "./pages/VerifyProfileImagePage";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import PrivateRoute from "./utils/PrivateRoute";



const App = () => {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/report" element={<PrivateRoute element={ReportPage} />} />
          <Route path="/inquiry" element={<PrivateRoute element={InquiryPage} />} />
          <Route path="/verify-profile-image" element={<PrivateRoute element={VerifyProfileImagePage} />} />
          <Route path="/change-password" element={<PrivateRoute element={ChangePasswordPage} />} />
          <Route path="*" element={<Navigate to="/report" />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default App;
