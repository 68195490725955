import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";

// Styled components definition
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 250px;
  width: calc(100% - 250px);
`;

const VerticalContainer = styled.div`
  display: flex;
  margin-left: 250px;
  flex-direction: column;
  width: calc(100% - 250px);
`;

const HorizontalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 10px;
  background-color: #F4F4F4;
`;

const Section = styled.div`
  background-color: #FFFFFF;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  min-height: 400px;
  margin: 10px;
  border-radius: 10px;
  width: ${props => props.width || '45%'};
`;

const Title = styled.h2`
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  margin-top: 0;
`;

const Divider = styled.hr`
  border: 1px solid #CCCCCC;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
  th, td {
    padding: 10px;
    border-bottom: 1px solid #CCCCCC;
  }
  th {
    text-align: left;
  }
  tr:hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }
`;

const Navigation = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const NavButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 2px 5px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 3px;

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const Label1 = styled.label`
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  flex: 0 0 100px;
`;

const Input = styled.input`
  width: 80%;
  padding: 8px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const SearchButton = styled.button`
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
`;

const DetailRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
`;

const Value = styled.span`
  font-weight: normal;
  margin-bottom: 5px;
  font-size: 12px;
  color: #333;
  display: inline-block;
  flex-grow: 1;
`;

const AddBerryButton = styled.button`
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #218838;
  }
`;

const CancelButton = styled.button`
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #c82333;
  }
`;

const AddBerryContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const AddBerryInput = styled.input`
  width: 100px;
  padding: 5px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const TeamList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const TeamItem = styled.li`
  margin-bottom: 5px;
  font-size: 12px;
  color: #333;
`;

const UserSearch = () => {
  const { currentUser } = useAuth(); 
  const [users, setUsers] = useState([]);
  const [error, setError] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserTeams, setSelectedUserTeams] = useState([]); // For storing teams
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [addingBerry, setAddingBerry] = useState(false);
  const [berryAmount, setBerryAmount] = useState("");

  const itemsPerPage = 10;

  const handleSearch = async () => {
    if (!currentUser) {
      setError("No user logged in.");
      return;
    }

    try {
      const response = await axios.post(
        `https://${process.env.REACT_APP_API_DNS}/api/admin/searchUser/${currentUser.adminId}`,
        { searchedUser: searchQuery },
        {
          headers: {
            'x-access-token': currentUser.accessToken,
          },
        }
      );

      if (response.status === 200) {
        setUsers(response.data.users);
        setError("");
        setCurrentPage(0);
        setSelectedUser(null);
      } else {
        setError("Failed to fetch users.");
      }
    } catch (error) {
      const status = error.response ? error.response.status : 500;
      const message = error.response ? error.response.data.message : "Network or server error.";
      setError(`Fetch failed: ${message} (Status: ${status})`);
    }
  };

  const handleUserClick = async (user) => {
    if (!currentUser) {
      setError("No user logged in.");
      return;
    }

    try {
      const response = await axios.post(
        `https://${process.env.REACT_APP_API_DNS}/api/admin/userDetails/${currentUser.adminId}`,
        { userId: user.id },
        {
          headers: {
            'x-access-token': currentUser.accessToken,
          },
        }
      );

      if (response.status === 200) {
        setSelectedUser(response.data.user);
        setSelectedUserTeams(response.data.teams); // Set the teams
        // console.log("Selected user:", response.data.user);
        // console.log("Selected user teams:", response.data.teams);
        setError("");
      } else {
        setError("Failed to fetch user details.");
      }
    } catch (error) {
      const status = error.response ? error.response.status : 500;
      const message = error.response ? error.response.data.message : "Network or server error.";
      setError(`Fetch failed: ${message} (Status: ${status})`);
    }
  };

  const handleAddBerryClick = () => {
    setAddingBerry(!addingBerry);
    setBerryAmount("");
  };

  const handleBerryAmountChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setBerryAmount(value);
    }
  };

  const handleAddBerrySubmit = async () => {
    if (!berryAmount || berryAmount < 1 || berryAmount > 1000000) {
      setError("Invalid amount. Please enter a number between 1 and 1,000,000.");
      return;
    }

    try {
      const response = await axios.post(
        `https://${process.env.REACT_APP_API_DNS}/api/admin/addBerry/${currentUser.adminId}`,
        { userId: selectedUser.id, berryAmount: parseInt(berryAmount) },
        {
          headers: {
            'x-access-token': currentUser.accessToken,
          },
        }
      );

      if (response.status === 200) {
        setSelectedUser({ ...selectedUser, berry: response.data.user.berry });
        setAddingBerry(false);
        setBerryAmount("");
        setError("");
      } else {
        setError("Failed to add berry.");
      }
    } catch (error) {
      const status = error.response ? error.response.status : 500;
      const message = error.response ? error.response.data.message : "Network or server error.";
      setError(`Failed to add berry: ${message} (Status: ${status})`);
    }
  };

  const handleNextPage = () => {
    if ((currentPage + 1) * itemsPerPage < users.length) {
      setCurrentPage(currentPage + 1);
      setSelectedUser(null);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
      setSelectedUser(null);
    }
  };

  const indexOfFirstUser = currentPage * itemsPerPage;
  const indexOfLastUser = indexOfFirstUser + itemsPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  return (
    <VerticalContainer>
      <HorizontalContainer>
        <Section width="35%" id="tableSection">
          <Title>사용자 검색</Title>
          <Divider />
          <div>
            <Input
              type="text"
              placeholder="사용자 닉네임, 이름, 또는 전화번호를 입력하세요"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <SearchButton onClick={handleSearch}>검색</SearchButton>
          </div>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <Table>
            <thead>
              <tr>
                <th>닉네임</th>
                <th>이름</th>
                <th>성별</th>
                <th>대학교</th>
                <th>학과</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers.map((user) => (
                <tr key={user.id} onClick={() => handleUserClick(user)}>
                  <td>{user.nickName}</td>
                  <td>{user.name}</td>
                  <td>{user.gender}</td>
                  <td>{user.university}</td>
                  <td>{user.department}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Navigation>
            <NavButton onClick={handlePreviousPage} disabled={currentPage === 0}>
              이전
            </NavButton>
            <NavButton onClick={handleNextPage} disabled={indexOfLastUser >= users.length}>
              다음
            </NavButton>
          </Navigation>
        </Section>
        <Section width="60%" id="detailSection">
          {selectedUser ? (
            <div>
              <Title>사용자 상세 정보</Title>
              <Divider />
              <DetailRow>
                <Label1>닉네임 </Label1>
                <Value>{selectedUser.nickName}</Value>
              </DetailRow>
              <DetailRow>
                <Label1>이름 </Label1>
                <Value>{selectedUser.name}</Value>
              </DetailRow>
              <DetailRow>
                <Label1>이메일 </Label1>
                <Value>{selectedUser.email}</Value>
              </DetailRow>
              <DetailRow>
                <Label1>성별 </Label1>
                <Value>{selectedUser.gender}</Value>
              </DetailRow>
              <DetailRow>
                <Label1>생년월일 </Label1>
                <Value>{selectedUser.birthdate}</Value>
              </DetailRow>
              <DetailRow>
                <Label1>전화번호 </Label1>
                <Value>{selectedUser.phoneNumber}</Value>
              </DetailRow>
              <DetailRow>
                <Label1>대학교 </Label1>
                <Value>{selectedUser.university}</Value>
              </DetailRow>
              <DetailRow>
                <Label1>학과 </Label1>
                <Value>{selectedUser.department}</Value>
              </DetailRow>
              <DetailRow>
                <Label1>MBTI </Label1>
                <Value>{selectedUser.mbti}</Value>
              </DetailRow>
              <DetailRow>
                <Label1>프로필 생성 </Label1>
                <Value>{selectedUser.profileCreate}</Value>
              </DetailRow>
              <DetailRow>
                <Label1>대학교 인증 </Label1>
                <Value>{selectedUser.verifyUniv ? "인증됨" : "미인증"}</Value>
              </DetailRow>
              <DetailRow>
                <Label1>프로필 이미지 </Label1>
                <Value><img src={selectedUser.profileImage} alt="Profile" width="100" /></Value>
              </DetailRow>
              <DetailRow>
                <Label1>전화 인증 </Label1>
                <Value>{selectedUser.verifyPhone ? "인증됨" : "미인증"}</Value>
              </DetailRow>
              <DetailRow>
                <Label1>팀 정보 </Label1>
                <Value>
                <TeamList>
                    {selectedUserTeams.length > 0 ? (
                      selectedUserTeams.map(team => (
                        <TeamItem key={team.id}>
                          {team.teamName} ({team.teamIntro})
                        </TeamItem>
                      ))
                    ) : (
                      <p>사용자가 소속된 팀이 없습니다.</p>
                    )}
                  </TeamList>
                </Value>
              </DetailRow>
              <DetailRow>
                <Label1>Berry </Label1>
                <Value>
                  {selectedUser.berry}
                  {addingBerry ? (
                    <CancelButton onClick={handleAddBerryClick}>취소하기</CancelButton>
                  ) : (
                    <AddBerryButton onClick={handleAddBerryClick}>베리 추가</AddBerryButton>
                  )}
                </Value>
              </DetailRow>
              {addingBerry && (
                <AddBerryContainer>
                  <AddBerryInput
                    type="number"
                    placeholder="Amount"
                    value={berryAmount}
                    onChange={handleBerryAmountChange}
                    min="1"
                    max="1000000"
                  />
                  <AddBerryButton
                    onClick={handleAddBerrySubmit}
                    disabled={!berryAmount || berryAmount < 1 || berryAmount > 1000000}
                  >
                    추가하기
                  </AddBerryButton>
                </AddBerryContainer>
              )}
              <DetailRow>
                <Label1>리뷰 </Label1>
                <Value>{selectedUser.reviews}</Value>
              </DetailRow>
              <DetailRow>
                <Label1>공개 리뷰 </Label1>
                <Value>{selectedUser.visibleReview}</Value>
              </DetailRow>
              <DetailRow>
                <Label1>차단된 사용자 </Label1>
                <Value>{selectedUser.blockedUsers}</Value>
              </DetailRow>
            </div>
          ) : (
            <p>사용자 항목을 선택해 주세요.</p>
          )}
        </Section>
      </HorizontalContainer>
    </VerticalContainer>
  );
};

export default UserSearch;
