import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext"; // 사용자의 인증 정보를 가져오기 위한 컨텍스트 사용

// 스타일 컴포넌트 정의
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 250px;
  width: calc(100% - 250px);
`;

const VerticalContainer = styled.div`
  display: flex;
  margin-left: 250px;
  flex-direction: column;  // This will stack children vertically
  width: calc(100% - 250px);
`;

const HorizontalContainer = styled.div`
  display: flex;
  justify-content: space-between;  // Keeps sections spaced evenly
  width: 100%;  // Use the full width
  margin-left: 10px;
  background-color: #F4F4F4;
`;

const Section = styled.div`
  background-color: #FFFFFF;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  min-height: 400px;
  margin: 10px;
  border-radius: 10px;
  width: ${props => props.width || '45%'};
`;

const UserInfoSection = styled(Section)`
  width: 100%; // 유저 정보 섹션은 전체 너비 사용
`;

const Title = styled.h2`
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  margin-top: 0;
`;

const Divider = styled.hr`
  border: 1px solid #CCCCCC;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
  th, td {
    padding: 10px;
    border-bottom: 1px solid #CCCCCC;
  }
  th {
    text-align: left;
  }
  tr:hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }
`;

const Navigation = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const NavButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 2px 5px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 3px;

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const Label1 = styled.label`
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  flex: 0 0 100px;
`;

const Label2 = styled.label`
  display: block;
  margin-bottom: 10px;
  font-size: 12px;
  color: #495057; // Darker grey for better readability
`;


const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
`;

const DetailRow = styled.div`
  display: flex;
  align-items: center;  // Aligns items vertically in the center
  margin-bottom: 2px;
`;

const Value = styled.span`
  font-weight: normal;
  margin-bottom: 5px;
  font-size: 12px;
  color: #333; // Dark gray for better readability
  display: inline-block;
  flex-grow: 1;
`;


const ReportList = () => {
  const { currentUser } = useAuth(); // 현재 사용자의 인증 정보 가져오기
  const [reports, setReports] = useState([]);
  const [error, setError] = useState("");
  const [selectedReport, setSelectedReport] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  // 테스트를 위한 더미 데이터 사용 여부
  const useDummyData = true;

  // 서버에서 데이터 가져오기 또는 더미 데이터 사용
  useEffect(() => {
    const fetchReports = async () => {
      if (!currentUser) {
        setError("No user logged in.");
        return;
      }

      try {
        const response = await axios.post(
          `https://${process.env.REACT_APP_API_DNS}/api/admin/getReports/${currentUser.adminId}`,
          {},
          {
            headers: {
              'x-access-token': currentUser.accessToken
            }
          }
        );

        if (response.status === 200) {
          setReports(response.data.reports.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
        } else {
          setError("Failed to fetch reports.");
        }
      } catch (error) {
        const status = error.response ? error.response.status : 500;
        const message = error.response ? error.response.data.message : "Network or server error.";
        setError(`Fetch failed: ${message} (Status: ${status})`);
      }
    };

    fetchReports();
  }, [currentUser]);

  // 페이지 이동 핸들러
  const handleNextPage = () => {
    if ((currentPage + 1) * itemsPerPage < reports.length) {
      setCurrentPage(currentPage + 1);
      setSelectedReport(null);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
      setSelectedReport(null);
    }
  };

  const handleReportClick = (report) => {
    setSelectedReport(report); // 선택된 신고 설정
  };

  const formatDate = (dateString) => {
    const [datePart, timePart] = dateString.split('T');
    const [year, month, day] = datePart.split('-');
    const [time] = timePart.split('.');
    const formattedString = `${year}년 ${month}월 ${day}일 ${time} GMT+0900 (한국표준시)`;

    return formattedString;
  };

  // 현재 페이지에 맞는 데이터 슬라이스
  const indexOfFirstReport = currentPage * itemsPerPage;
  const indexOfLastReport = indexOfFirstReport + itemsPerPage;
  const currentReports = reports.slice(indexOfFirstReport, indexOfLastReport);

  return (
    <VerticalContainer>
      <HorizontalContainer>
        <Section width="35%" id="tableSection">
          <Title>신고 목록 보기</Title>
          <Divider />
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <Table>
            <thead>
              <tr>
                <th>일자</th>
                <th>신고 유형</th>
                <th>신고자</th>
                <th>피신고자</th>
              </tr>
            </thead>
            <tbody>
              {currentReports.map((report) => (
                <tr key={report.id} onClick={() => handleReportClick(report)}>
                  <td>{new Date(report.createdAt).toLocaleDateString()}</td>
                  <td>{report.reportType}</td>
                  <td>{report.reporterDetails.nickName}</td>
                  <td>{report.reportedDetails.nickName}</td>
                  {/* <td>{report.reportContent.substring(0, 20)}...</td> 내용의 일부만 표시 */}
                </tr>
              ))}
            </tbody>
          </Table>
          <Navigation>
            <NavButton onClick={handlePreviousPage} disabled={currentPage === 0}>
              이전
            </NavButton>
            <NavButton onClick={handleNextPage} disabled={indexOfLastReport >= reports.length}>
              다음
            </NavButton>
          </Navigation>
        </Section>
        <Section width="60%" id="detailSection">
          {selectedReport ? (
            <div>
              <Title>신고 내역 상세</Title>
              <Divider />
              <DetailRow>
                <Label1>신고 일시 </Label1>
                <Value>{formatDate(selectedReport.createdAt)}</Value>
              </DetailRow>
              <DetailRow>
                <Label1>신고자 </Label1>
                <Value>{selectedReport.reporterDetails.nickName} | {selectedReport.reporterDetails.name} | {selectedReport.reporterDetails.phoneNumber}</Value>
              </DetailRow>
              <DetailRow>
                <Label1>피신고자 </Label1>
                <Value>{selectedReport.reportedDetails.nickName} | {selectedReport.reportedDetails.name} | {selectedReport.reportedDetails.phoneNumber}</Value>
              </DetailRow>
              <DetailRow>
                <Label1>신고 유형 </Label1>
                <Value>{selectedReport.reportType}</Value>
              </DetailRow>
              <DetailRow>
                <Label1>신고 내용 </Label1>
                <Value>{selectedReport.reportContent}</Value>
              </DetailRow>
            </div>
          ) : (
            <p>신고 항목을 선택해 주세요.</p>
          )}
        </Section>
      </HorizontalContainer>
    </VerticalContainer>
  );
};

export default ReportList;
